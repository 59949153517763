import { useAccount, useConfig, usePublicClient, useWalletClient } from "wagmi";

const useActiveWagmi = () => {
  const { address, connector, isConnecting, isConnected, chain } = useAccount();
  const { chains } = useConfig();
  const provider = usePublicClient();
  const { data: signer } = useWalletClient();
  const chainId = chain?.id;
  const library = provider;
  const supportedChains = chains.map((chain) => chain.id);
  const isNetworkSupported = Boolean(
    isConnected && chain && supportedChains.includes(chain.id),
  );

  console.log("is updated");

  return {
    account: address,
    isConnected,
    isConnecting,
    chain,
    chains,
    chainId,
    library,
    signer,
    connector,
    isNetworkSupported,
  };
};

export default useActiveWagmi;
