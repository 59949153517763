import { useReadContract, useReadContracts } from "wagmi";

import useWatchContract from "./useWatchContract";

const useContractReadWithTransactionWatch = (
  config: any,
  enabled = true,
  watch = true,
) => {
  const readContractFunction = config?.contracts?.length
    ? useReadContracts
    : useReadContract;
  const {
    isPending: isLoading,
    queryKey,
    ...rest
    // @ts-expect-error
  } = readContractFunction({
    ...config,
    query: {
      enabled,
    },
  });
  useWatchContract(watch ? queryKey : undefined);

  return {
    ...rest,
    queryKey,
    isLoading,
  };
};

export default useContractReadWithTransactionWatch;
