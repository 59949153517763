import type { QueryKey } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useBlockNumber } from "wagmi";

const useWatchContract = (queryKey?: QueryKey | QueryKey[]) => {
  const queryClient = useQueryClient();
  const { data: blockNumber } = useBlockNumber({ watch: true });

  useEffect(() => {
    if (Array.isArray(queryKey)) {
      queryKey.map((key) => queryClient.invalidateQueries({ queryKey: key }));
      return;
    }
    if (queryKey) {
      queryClient.invalidateQueries({ queryKey });
    }
  }, [blockNumber, queryClient]);
};

export default useWatchContract;
